@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DISABLE_UNUSED_ROUTES__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../shared/scss/dt-base';

.container {
  &:global(__content) {
    max-width: 570px;
  }

  :global {
    .modal__header-close-button {
      display: none;
    }
  }
}

.header {
  font-size: rem(22);
  font-weight: 700;
  border-bottom: 3px solid $tw-gray-02;
  width: 100%;
  padding-left: 3px;
}

.content {
  display: flex;
  flex-direction: column;
  margin-top: 19px;
}

.subtitle {
  display: flex;
  flex-direction: column;
  font-size: rem(16);
  font-weight: 800;
  margin-bottom: 23px;
}

.text-blurred {
  color: $cec-medium-dark-gray;
  font-weight: 500;
  text-transform: lowercase;
}

.error-banner {
  margin-top: 12px;
  border-width: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  :global .warning-icon {
    position: unset;
    margin-right: 10px;
  }
}

.search-store-btn {
  margin-top: 26px;
  margin-left: auto;
  width: 245px;
  background-color: $cec-black;
  &:hover,
  &:active,
  &:focus {
    background-color: $cec-black;
  }
}

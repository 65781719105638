@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DISABLE_UNUSED_ROUTES__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../../../../../../shared/scss/dt-base';

.description {
  color: $medium-gray;
  font-size: rem(12);
}

.nav {
  display: flex;
  position: relative;
  z-index: 1;
}

.tab {
  border: 1px solid $medium-light-gray;
  border-radius: 3px;
  color: $dark-gray;
  flex: 0 1 80px;
  font-size: rem(14);
  font-weight: 700;
  height: 34px;
  line-height: rem(17);
  margin-right: 2px;
}

.tab-selected,
.tab-muted {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.tab-selected {
  @extend .tab;
  background-color: $light-gray;
  border-bottom: none;
  border-color: $dark-gray;
  font-weight: 900;
}

.tab-muted {
  @extend .tab;
  border-bottom: 1px solid transparent;
  color: $medium-light-gray;
}

.container {
  background-color: $light-gray;
  border: 1px solid $dark-gray;
  padding: 16px 20px 18px 20px;
  position: relative;
  top: -1px;
  z-index: 0;
}

.collapsible {
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid $medium-light-gray;

  &:global(__content) {
    padding: 0px;
  }
}

.description {
  display: flex;
  align-items: center;

  font-size: rem(13);
}

.offset {
  margin: 3px 10px 0 0;
  align-self: flex-start;
}

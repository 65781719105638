@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DISABLE_UNUSED_ROUTES__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../../../../shared/scss/_dt-base';

%button {
  align-items: center;
  background: $light-gray;
  color: $medium-light-gray;
  font-family: Lato, sans-serif;
  flex-grow: 1;
  font-size: rem(13);
  height: 50px;
  justify-content: center;
  padding: 2px 20px 0 20px;
  text-transform: uppercase;
  width: 111px;

  @include media($min-sm) {
    flex-grow: 0;
    min-width: 126px;
    padding: 2px 25px 0px;
  }

  > span {
    white-space: nowrap;

    &:first-child {
      font-size: rem(10);
    }

    &:last-child {
      font-weight: bold;
    }
  }
}

.default-button {
  @extend %button;

  &:first-child {
    border-top-left-radius: 5px;
  }

  &:last-child {
    border-top-right-radius: 5px;
  }

  + .default-button {
    border-left: 1px solid $white;
  }
}

.active-button {
  @extend %button;
  background: $white;
  border-radius: 5px 5px 0 0;

  > span {
    &:first-child {
      color: $medium-light-gray;
    }

    &:last-child {
      color: $dark-gray;
    }
  }
}

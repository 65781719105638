@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DISABLE_UNUSED_ROUTES__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../../../../shared/scss/dt-base';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 36px;
  color: $black;

  @include media($min-sm) {
    margin-bottom: 72px;
  }
}

.heading {
  margin: 16px 0;
  text-align: center;
  max-width: 230px;

  @include media($min-sm) {
    max-width: 100%;
  }
}

.more-info-button {
  text-align: center;
  width: 100%;
  margin-bottom: 16px;
  margin-top: -12px;
}

%more-info-container {
  transition:
    margin 0.4s ease-out 0.1s,
    max-height 0.4s ease;
  overflow: hidden;
  margin: 0 -16px;

  @include media($min-sm) {
    max-width: 800px;
    margin: 0 auto;
  }

  :global .fitment-tire-size-help-guide__container {
    margin-bottom: 16px;
  }
}

.more-info-open {
  @extend %more-info-container;
  max-height: 1000px;
}

.more-info-closed {
  @extend %more-info-container;
  max-height: 0;
}

@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DISABLE_UNUSED_ROUTES__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../shared/scss/dt-base';

.size-badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  width: 35px;
  height: 27px;
  background: $gold-500;
  border-radius: 2px;
  color: $white;

  @include media($min-sm) {
    width: 35px;
    height: 27px;
    margin-top: 8px;
  }
}

.size-badge-oe {
  background: $red-700;
}
